import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { gfxChatterStyles, smallCapsStyles, heading2Styles } from "@apdata/core/typography"
import { listToSentence } from "js/utils"
import { formatDate } from "ap-react-utils"
import { OutboundLink } from "react-ga"
import { getCanonicalUrl } from "../../utils"
import { neutrals } from "@apdata/core/colors"

const Container = styled.div`
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-gap: 0.6em;
  margin-bottom: 2em;

  @media only screen and (max-width: 450px) {
    display: block;
  }
`

const Header = styled.div`
  grid-column: 1 / 3;
`

const Headline = styled.h2`
  ${heading2Styles}
  font-weight: bold;
  display: inline-block;
  margin: 0 1em 0.75rem 0;
`

const PlaceHolder = styled.figure`
  margin: 0;
  width: 180px;
  height: 100px;
  margin-bottom: 0.6em;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  vertical-align: bottom;
  border-radius: 2px;
`

const Metadata = styled.div`
  ${smallCapsStyles}
  margin-bottom: 0.6em;
  color: ${neutrals.gray80};
`

const Description = styled.figcaption`
  ${gfxChatterStyles}
  color: ${neutrals.gray80};
`

const Link = styled(OutboundLink)`
  text-decoration: none;
  color: inherit;
`

function Card({
  index,
  title,
  description,
  image,
  authors,
  published,
  updated,
  canonicals,
  ...props
}) {
  const byline =
    authors && `By ${listToSentence(authors.map((a) => a.name.toUpperCase()))}`
  const publishDate = published || updated
  const dateline = publishDate && formatDate(new Date(publishDate))
  const canonicalUrl = getCanonicalUrl(props, canonicals)

  return (
    <Link eventLabel={`project-index-link-card-${index}`} to={canonicalUrl}>
      <Container>
        <Header>
          <Headline>{title}</Headline>
        </Header>
        <PlaceHolder>
          <Image src={`${image}`} loading="lazy" />
        </PlaceHolder>
        <div>
          <Metadata>
            {[byline, dateline].filter(Boolean).join(", ")}
          </Metadata>
          <Description>{description}</Description>
        </div>
      </Container>
    </Link>
  )
}

Card.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  authors: PropTypes.array,
  updated: PropTypes.string,
  slug: PropTypes.string,
  canonicals: PropTypes.arrayOf(
    PropTypes.shape({ url: PropTypes.string, slug: PropTypes.string })
  ),
}

Card.defaultProps = {}

export default Card
