import React from "react"
import ReactGA from "react-ga"
import { getDisplayName } from "ap-interactives-plumbing"

export function withPageView(gaID, opts = {}) {
  const { force = false } = opts
  return (WrappedComponent) => {
    function WithPageView(props) {
      React.useEffect(() => {
        // eslint-disable-next-line no-undef
        if (force || process.env.NODE_ENV === "production") {
          ReactGA.initialize(gaID)
        }
        ReactGA.pageview(window.location.pathname + window.location.search)
      }, [])
      return <WrappedComponent {...props} />
    }
    WithPageView.displayName = `WithPageView(${getDisplayName(
      WrappedComponent
    )})`
    return WithPageView
  }
}
