import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { listToSentence } from "js/utils"
import { formatDate } from "ap-react-utils"
import { OutboundLink } from "react-ga"
import { getCanonicalUrl } from "../../utils"
import { SmallCaps, gfxChatterStyles, heading1Styles, smallCapsStyles } from "@apdata/core/typography"
import { neutrals } from "@apdata/core/colors"

const Container = styled.div``

const Headline = styled.h2`
  ${heading1Styles}
  margin: 0 0 0.5rem;
`

const PlaceHolder = styled.figure`
  margin: 0;
  margin-bottom: 0.6em;
  padding-bottom: 56.23%;
  position: relative;
`

const Image = styled.img`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Metadata = styled.div`
  margin-bottom: 0.6em;
  ${smallCapsStyles}
  color: ${neutrals.gray80};
`

const Description = styled.figcaption`
  ${gfxChatterStyles}
  color: ${neutrals.gray80};
`

const Link = styled(OutboundLink)`
  text-decoration: none;
  color: inherit;
`

function Hero({
  title,
  description,
  image,
  authors,
  updated,
  canonicals,
  ...props
}) {
  const byline =
    authors && `By ${listToSentence(authors.map((a) => a.name.toUpperCase()))}`
  const dateline = updated && formatDate(new Date(updated))

  const canonicalUrl = getCanonicalUrl(props, canonicals)

  return (
    <Link eventLabel="project-index-link-hero" to={canonicalUrl}>
      <Container>
        <Headline>{title}</Headline>
        <Metadata>{[byline, dateline].filter(Boolean).join(", ")}</Metadata>
        <PlaceHolder>
          <Image src={`${image}`} preload="auto" />
        </PlaceHolder>
        <Description>{description}</Description>
      </Container>
    </Link>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  authors: PropTypes.array,
  updated: PropTypes.string,
  slug: PropTypes.string,
  canonicals: PropTypes.arrayOf(
    PropTypes.shape({ url: PropTypes.string, slug: PropTypes.string })
  ),
}

Hero.defaultProps = {}

export default Hero
