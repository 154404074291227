import { render, compose } from "ap-interactives-plumbing"
import { withPageView } from "./with_page_view"
import C from "js/components/app"
import "scss/index.scss"

const enhance = compose(withPageView("UA-19104461-33"))
const Component = enhance(C)

if (!window.__HTML_WEBPACK_PRERENDER_PLUGIN__) {
  const selector =
    'div.ap-interactive[data-interactive="projects-apnews"][data-entrypoint="index"]'
  const nodes = document.querySelectorAll(selector)
  nodes.forEach((node) => render.renderOrHydrate(Component, node))
}

export default render.ssr(Component)
