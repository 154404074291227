// 1. add the metadata JSON URL here (double-check it exists by visiting it)
const projects = [
  "https://apnews.com/projects/mexico-tren-maya-climate-yucatan/index-metadata.json",
  "/features/2023/the-protein-problem/index-metadata.json",
  "/features/2023/missing-students-housing/index-metadata.json",
  "/features/2023/mass-killings/index-metadata.json",
  "/features/2023/missing-students-chronic-absenteeism/index-metadata.json",
  "/features/2023/climate-change-disease-animals-warming-earth/index-metadata.json",
  "/features/2023/from-birth-to-death/index-metadata.json",
  "/features/2023/adrift/index-metadata.json",
  "/features/2023/ski-resorts-climate/index-metadata.json",
  "/features/2023/ukraine-one-year/index-metadata.json",
  "/features/2023/missing-children/index-metadata.json",
  "/features/2022/climate-photos/index-metadata.json",
  "/features/2022/coral-reef-immersive/index-metadata.json",
  "/features/2021/global-extreme-heat/index-metadata.json",
  "/features/2021/polar-bears-sea-ice/index-metadata.json",
  "/features/2021/gaza-toll-of-war/gaza-toll-one-neighborhood-metadata.json",
  "/features/2021/gaza-toll-of-war/gaza-toll-by-the-numbers-metadata.json",
  "/features/2021/gaza-toll-of-war/gaza-toll-video-after-the-strike-metadata.json",
]

// 2. then add the canonical URL here
const canonicalUrls = [
  "https://apnews.com/article/from-birth-to-death-series-2b2f56844fe6f309be541b45aef2e281",
  "https://apnews.com/article/adrift-investigation-migrants-mauritania-tobago-663a576e233cb4b363f5eda8d5969b5a",
  "https://apnews.com/article/climate-change-activism-ski-industry-028d83e19543db5d7633ea83932bdde3",
  "https://apnews.com/article/russia-ukraine-war-one-year-index-dc5ee4b342ce80742c471e19258a2046",
  "https://apnews.com/article/covid-school-enrollment-missing-kids-homeschool-b6c9017f603c00466b9e9908c5f2183a",
  "https://apnews.com/article/2022-climate-change-photos-3d0d1a338bf8995048bddae11ad17eb2",
  "https://apnews.com/article/Death-regrowth-on-Great-Barrier-Reef-081570270011",
]

/**
 * Extracts the string between the second to last and last forward slash for each URL in the given list
 * that contains 'apnews.com'.
 *
 * @param {string[]} urls - An array of absolute or relative URL strings to process.
 * @returns {string[]} An array containing the extracted strings aka slugs
 */
const extractSlugs = (urls) => {
  const result = []

  for (const url of urls) {
    if (url.includes("apnews.com")) {
      const splitUrl = url.split("/")

      if (splitUrl.length > 2) {
        const lastIndex = splitUrl.length - 1
        const desiredString = splitUrl[lastIndex - 1]
        result.push(desiredString)
      }
    }
  }

  return result
}

const slugs = extractSlugs(projects)
const canonicals = {}
for (const [index, slug] of slugs.entries()) {
  canonicals[slug] = canonicalUrls[index]
}

export { canonicals, projects }
