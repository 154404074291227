import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Article, Nav, Section, Footer } from "tailor"
import apLogo from "ap-interactive-assets/images/AP_LOGO_38x44.png"
import Hero from "js/components/hero"
import Card from "js/components/card"
import hardcodedMetadata from "./hardcoded_metadata.json"
import { projects, canonicals } from "../../CONFIG"

const StyledArticle = styled(Article)`
  min-height: calc(100vh - 160px);
  padding-top: 60px;
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4em;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    max-width: 900px;
    margin: 0 auto;
    grid-gap: 3em;
  }
`

const Others = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    max-width: 600px;
    margin: 0 auto;
  }
`

function App() {
  const [projectData, setProjectData] = useState("[]")

  useEffect(() => {
    const getProjects = async () => {
      // eslint-disable-next-line no-undef
      const results = await Promise.all(
        projects.map((url) => {
          if (hardcodedMetadata[url]) return hardcodedMetadata[url]
          return fetch(url).then((r) => r.json())
        })
      )
      setProjectData(JSON.stringify(results))
    }

    // .then((proj) => setProjectDataAtIndex(proj, i))
    // .catch(() => setProjectDataAtIndex(null, i));
    getProjects()
  }, [])

  const [hero, ...otherProjects] = JSON.parse(projectData)

  return (
    <div>
      <Nav img={apLogo} href="https://apnews.com" dark />
      <StyledArticle>
        <Section wide>
          <Layout>
            {hero && <Hero canonicals={canonicals} {...hero} />}
            <Others>
              {otherProjects.filter(Boolean).map((project, i) => {
                return (
                  <Card
                    key={i}
                    index={i}
                    canonicals={canonicals}
                    {...project}
                  />
                )
              })}
            </Others>
          </Layout>
        </Section>
      </StyledArticle>
      <Footer dark />
    </div>
  )
}

App.propTypes = {}

App.defaultProps = {}

export default App
